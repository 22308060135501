import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
import { getToken } from '@/utils/auth';
import { checkChat } from '@/api/chat';
import { parseTime } from '@/utils/index';
export default {
  name: 'App',
  data: function data() {
    return {
      lastRefresh: null
    };
  },
  mounted: function mounted() {
    var _this = this;

    if (this.$appModule !== 'admin') {
      this.messageTimer = setInterval(function () {
        var token = getToken();

        if (token != null && token.length > 0 && _this.$route.path !== '/message') {
          var lastRefresh = _this.lastRefresh == null ? null : parseTime(_this.lastRefresh);
          checkChat(lastRefresh).then(function (res) {
            var code = res.code,
                data = res.data;

            if (code === 0) {
              var count = data.reduce(function (a, c) {
                return a + c.unread_count;
              }, 0);

              _this.$store.dispatch('app/setUnreadCount', count);
            }
          }).finally(function () {// this.lastRefresh = new Date();
          });
        }
      }, 30 * 1000);
    }
  }
};