import "core-js/modules/es.array.concat.js";
import Cookies from 'js-cookie';
import Vue from 'vue';
var TokenKey = 'hb_token';
export function getToken() {
  return Cookies.get("".concat(Vue.prototype.$appModule, "_").concat(TokenKey));
}
export function setToken(token) {
  return Cookies.set("".concat(Vue.prototype.$appModule, "_").concat(TokenKey), token);
}
export function removeToken() {
  return Cookies.remove("".concat(Vue.prototype.$appModule, "_").concat(TokenKey));
}