import Cookies from 'js-cookie';
var state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  unreadCount: 0
};
var mutations = {
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(State) {
    var StateClone = State;
    StateClone.sidebar.opened = !StateClone.sidebar.opened;
    StateClone.sidebar.withoutAnimation = false;

    if (StateClone.sidebar.opened) {
      Cookies.set('sidebarStatus', 1);
    } else {
      Cookies.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(State, withoutAnimation) {
    var StateClone = State;
    Cookies.set('sidebarStatus', 0);
    StateClone.sidebar.opened = false;
    StateClone.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(State, device) {
    var StateClone = State;
    StateClone.device = device;
  },
  SET_UNREAD_COUNT: function SET_UNREAD_COUNT(State, unreadCount) {
    var StateClone = State;
    StateClone.unreadCount = unreadCount;
  }
};
var actions = {
  toggleSideBar: function toggleSideBar(_ref) {
    var commit = _ref.commit;
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref2, _ref3) {
    var commit = _ref2.commit;
    var withoutAnimation = _ref3.withoutAnimation;
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref4, device) {
    var commit = _ref4.commit;
    commit('TOGGLE_DEVICE', device);
  },
  setUnreadCount: function setUnreadCount(_ref5, unreadCount) {
    var commit = _ref5.commit;
    commit('SET_UNREAD_COUNT', unreadCount);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};