import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/layout';
Vue.use(Router);
var routes;

if (Vue.prototype.$appModule === 'teacher') {
  routes = [{
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    meta: {// permissions: ['SP001'],
    },
    children: [{
      path: 'dashboard',
      component: function component() {
        return import('@/views/schedule/index');
      },
      meta: {
        title: 'routes.my_schedule',
        icon: 'schedule'
      }
    }]
  }, {
    path: '/course',
    component: Layout,
    redirect: '/course',
    meta: {// permissions: ['SP001'],
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/course/index');
      },
      meta: {
        title: 'routes.course',
        icon: 'course'
      }
    }]
  }, {
    path: '/message',
    component: Layout,
    redirect: '/message',
    meta: {// permissions: ['SP001'],
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/message/index');
      },
      meta: {
        title: 'routes.my_message',
        icon: 'message'
      }
    }, {
      path: 'detail',
      component: function component() {
        return import('@/views/message/detail');
      },
      meta: {
        title: 'routes.message_detail',
        noCache: true,
        activeMenu: '/message'
      },
      hidden: true
    }]
  }, {
    path: '/info',
    component: Layout,
    redirect: '/info',
    meta: {// permissions: ['SP001'],
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/info/index');
      },
      meta: {
        title: 'routes.info',
        icon: 'avatar'
      }
    }]
  }];
} else if (Vue.prototype.$appModule === 'student') {
  routes = [{
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    meta: {// permissions: ['SP001'],
    },
    children: [{
      path: 'dashboard',
      component: function component() {
        return import('@/views/schedule/index');
      },
      meta: {
        title: 'routes.my_schedule',
        icon: 'schedule'
      }
    }]
  }, {
    path: '/courseware',
    component: Layout,
    redirect: '/courseware',
    meta: {// permissions: ['SP001'],
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/courseware/index');
      },
      meta: {
        title: 'routes.my_courseware',
        icon: 'courseware'
      }
    }]
  }, {
    path: '/message',
    component: Layout,
    redirect: '/message',
    meta: {// permissions: ['SP001'],
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/message/index');
      },
      meta: {
        title: 'routes.my_message',
        icon: 'message'
      }
    }, {
      path: 'detail',
      component: function component() {
        return import('@/views/message/detail');
      },
      meta: {
        title: 'routes.message_detail',
        noCache: true,
        activeMenu: '/message'
      },
      hidden: true
    }]
  }, {
    path: '/info',
    component: Layout,
    redirect: '/info',
    meta: {// permissions: ['SP001'],
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/info/index');
      },
      meta: {
        title: 'routes.info',
        icon: 'avatar'
      }
    }]
  }];
} else {
  routes = [{
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    meta: {// permissions: ['SP001'],
    },
    children: [{
      path: 'dashboard',
      component: function component() {
        return import('@/views/dashboard/index');
      },
      meta: {
        title: 'routes.dashboard',
        icon: 'dashboard'
      }
    }]
  }, {
    path: '/frontpage',
    component: Layout,
    redirect: '/frontpage/teacher',
    meta: {
      title: 'routes.frontpage',
      icon: 'frontpage' // permissions: ['SP001'],

    },
    children: [{
      path: 'teacher',
      component: function component() {
        return import('@/views/frontpage/index');
      },
      meta: {
        title: 'routes.frontpage_tutor'
      }
    }, {
      path: 'course',
      component: function component() {
        return import('@/views/frontpage/index');
      },
      meta: {
        title: 'routes.frontpage_course'
      }
    }]
  }, {
    path: '/banners',
    component: Layout,
    redirect: '/banners',
    meta: {// permissions: ['SP001'],
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/banners/index');
      },
      meta: {
        title: 'routes.banners',
        icon: 'banner'
      }
    }]
  }, {
    path: '/category',
    component: Layout,
    redirect: '/category/subject',
    meta: {
      title: 'routes.category',
      icon: 'category' // permissions: ['SP001'],

    },
    children: [{
      path: 'subject',
      component: function component() {
        return import('@/views/category/index');
      },
      meta: {
        title: 'routes.category_subject'
      }
    }, {
      path: 'grade',
      component: function component() {
        return import('@/views/category/index');
      },
      meta: {
        title: 'routes.category_grade'
      }
    }]
  }, {
    path: '/course',
    component: Layout,
    redirect: '/course',
    meta: {// permissions: ['SP001'],
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/course/index');
      },
      meta: {
        title: 'routes.course',
        icon: 'course'
      }
    }]
  }, {
    path: '/tutor',
    component: Layout,
    redirect: '/tutor',
    meta: {// permissions: ['SP001'],
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/tutor/index');
      },
      meta: {
        title: 'routes.tutor',
        icon: 'tutor'
      }
    }]
  }, {
    path: '/schedule',
    component: Layout,
    redirect: '/schedule',
    meta: {// permissions: ['SP001'],
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/schedule/index');
      },
      meta: {
        title: 'routes.schedule',
        icon: 'schedule'
      }
    }]
  }, {
    path: '/courseware',
    component: Layout,
    redirect: '/courseware',
    meta: {// permissions: ['SP001'],
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/courseware/index');
      },
      meta: {
        title: 'routes.courseware',
        icon: 'courseware'
      }
    }]
  }, {
    path: '/order',
    component: Layout,
    redirect: '/order/course',
    meta: {
      title: 'routes.order',
      icon: 'order' // permissions: ['SP001'],

    },
    children: [{
      path: 'course',
      component: function component() {
        return import('@/views/order/index');
      },
      meta: {
        title: 'routes.order_course'
      }
    }, {
      path: 'course/view',
      component: function component() {
        return import('@/views/order/OrderDetail');
      },
      meta: {
        title: 'order.info',
        noCache: true,
        activeMenu: '/order/course'
      },
      hidden: true
    }, {
      path: 'courseware',
      component: function component() {
        return import('@/views/order/index');
      },
      meta: {
        title: 'routes.order_courseware'
      }
    }, {
      path: 'courseware/view',
      component: function component() {
        return import('@/views/order/OrderDetail');
      },
      meta: {
        title: 'order.info',
        noCache: true,
        activeMenu: '/order/courseware'
      },
      hidden: true
    }]
  }, {
    path: '/user',
    component: Layout,
    redirect: '/user',
    meta: {
      title: 'routes.user',
      icon: 'user' // permissions: ['SP001'],

    },
    children: [{
      path: 'list',
      component: function component() {
        return import('@/views/user/user');
      },
      meta: {
        title: 'routes.user_index'
      }
    }, {
      path: 'user_detail',
      component: function component() {
        return import('@/views/user/UserDetail');
      },
      meta: {
        title: 'routes.user_detail',
        noCache: true,
        activeMenu: '/user/list'
      },
      hidden: true
    }, {
      path: 'student',
      component: function component() {
        return import('@/views/user/student');
      },
      meta: {
        title: 'routes.user_student'
      }
    }, {
      path: 'student_detail',
      component: function component() {
        return import('@/views/user/StudentDetail');
      },
      meta: {
        title: 'routes.student_detail',
        noCache: true,
        activeMenu: '/user/student'
      },
      hidden: true
    }]
  }, {
    path: '/news',
    component: Layout,
    redirect: '/news',
    meta: {// permissions: ['SP001'],
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/news/index');
      },
      meta: {
        title: 'routes.news',
        icon: 'news'
      }
    }]
  }, {
    path: '/feedback',
    component: Layout,
    redirect: '/feedback',
    meta: {// permissions: ['SP001'],
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/feedback/index');
      },
      meta: {
        title: 'routes.feedback',
        icon: 'feedback'
      }
    }, {
      path: 'detail',
      component: function component() {
        return import('@/views/feedback/detail');
      },
      meta: {
        title: 'routes.feedback_detail',
        noCache: true,
        activeMenu: '/feedback'
      },
      hidden: true
    }]
  }, {
    path: '/message',
    component: Layout,
    redirect: '/message',
    meta: {// permissions: ['SP001'],
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/message/index');
      },
      meta: {
        title: 'routes.message',
        icon: 'message'
      }
    }, {
      path: 'detail',
      component: function component() {
        return import('@/views/message/detail');
      },
      meta: {
        title: 'routes.message_detail',
        noCache: true,
        activeMenu: '/message'
      },
      hidden: true
    }]
  }, {
    path: '/settings',
    component: Layout,
    redirect: '/settings/account',
    meta: {
      title: 'routes.settings',
      icon: 'settings' // permissions: ['SP001'],

    },
    children: [{
      path: 'account',
      component: function component() {
        return import('@/views/settings/account');
      },
      meta: {
        title: 'routes.settings_account'
      }
    } // {
    //   path: 'system',
    //   component: () => import('@/views/settings/system'),
    //   meta: {
    //     title: 'routes.settings_system',
    //   },
    // },
    ]
  }, {
    path: '/datastat',
    component: Layout,
    redirect: '/datastat',
    meta: {// permissions: ['SP001'],
    },
    children: [{
      path: '',
      component: function component() {
        return import('@/views/datastat/index');
      },
      meta: {
        title: 'routes.datastat',
        icon: 'md-map'
      }
    }]
  }];
} // 404 page must be placed at the end !!!


routes.push({
  path: '*',
  redirect: '/404',
  hidden: true
});
export var asyncRoutes = routes;
/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export var constantRoutes = [{
  path: '/login',
  component: function component() {
    return Vue.prototype.$appModule === 'student' ? import('@/views/login/student') : import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/404');
  },
  hidden: true
}];

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;