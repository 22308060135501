import defaultSettings from '@/settings';
import Vue from 'vue';
var showSettings = defaultSettings.showSettings,
    fixedHeader = defaultSettings.fixedHeader,
    sidebarLogo = defaultSettings.sidebarLogo;
var timezone = Vue.prototype.$appModule === 'teacher' ? 1 : 0;
var localTimezone = localStorage.getItem('timezone');

if (localTimezone != null) {
  timezone = localTimezone;
}

var state = {
  showSettings: showSettings,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  timezone: timezone
};
var mutations = {
  CHANGE_SETTING: function CHANGE_SETTING(State, _ref) {
    var key = _ref.key,
        value = _ref.value;
    var stateClone = State;
    stateClone[key] = value;

    if (key === 'timezone') {
      localStorage.setItem('timezone', value);
    }
  }
};
var actions = {
  changeSetting: function changeSetting(_ref2, data) {
    var commit = _ref2.commit;
    commit('CHANGE_SETTING', data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};