import request from '@/utils/http';
export function checkChat(lastRefresh) {
  return request({
    url: '/chat/check',
    method: 'get',
    params: {
      begin_datetime: lastRefresh
    }
  });
}
export function getChatList(params) {
  return request({
    url: '/chat/',
    method: 'get',
    params: params
  });
}
export function getChat(code) {
  return request({
    url: "/chat/".concat(code),
    method: 'get'
  });
}
export function getMessageList(code, params) {
  return request({
    url: "/chat/".concat(code, "/message/"),
    method: 'get',
    params: params
  });
}
export function sendMessage(code, data) {
  return request({
    url: "/chat/".concat(code, "/message/"),
    method: 'post',
    data: data
  });
}
export function createChat(data) {
  return request({
    url: '/chat/',
    method: 'post',
    data: data
  });
}