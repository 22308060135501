import "core-js/modules/es.function.name.js";
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  unreadCount: function unreadCount(state) {
    return state.app.unreadCount;
  },
  name: function name(state) {
    return state.user.name;
  },
  en_name: function en_name(state) {
    return state.user.en_name;
  },
  permissions: function permissions(state) {
    return state.user.permissions;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  timezone: function timezone(state) {
    return state.settings.timezone;
  }
};
export default getters;