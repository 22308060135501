import _objectSpread from "/root/workspace/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.regexp.test.js";

/* eslint-disable no-multi-str */
import Cookies from 'js-cookie';
import LightBox from 'vue-image-lightbox';
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css';
import XLSX from 'xlsx';
import axios from 'axios';
import { getToken } from '@/utils/auth';
import Editor from '@tinymce/tinymce-vue';
import { parseTime } from '@/utils';
import Vue from 'vue';
var file = {
  methods: {
    viewUrl: function viewUrl(code) {
      return "".concat(Vue.prototype.$appBaseApi || process.env.VUE_APP_BASE_API).concat(process.env.VUE_APP_MANAGE_API, "/file/").concat(code, "/view");
    }
  }
};
var image = {
  components: {
    LightBox: LightBox
  },
  data: function data() {
    return {
      srcList: ['']
    };
  },
  methods: {
    viewImage: function viewImage(src) {
      this.srcList[0] = {
        src: src
      };
      this.$refs.lightbox.showImage(0);
    }
  }
};
var CONVERTED_LABELS = [];
var LANGUAGE_MAPPING = {
  name: {
    zh: 'name',
    en: 'en_name'
  },
  stu_name: {
    zh: 'name',
    en: ['py_name', 'en_name']
  },
  course_snapshot_title: {
    zh: 'course_snapshot_title',
    en: 'course_snapshot_en_title'
  },
  student_name: {
    zh: 'student_name',
    en: ['student_py_name', 'student_en_name']
  },
  stu_user_name: {
    zh: 'user_name',
    en: ['user_py_name', 'user_en_name']
  },
  subject_category_name: {
    zh: 'subject_category_name',
    en: 'subject_category_en_name'
  },
  title: {
    zh: 'title',
    en: 'en_title'
  },
  teacher_name: {
    zh: 'teacher_name',
    en: 'teacher_en_name'
  },
  courseware_snapshot_title: {
    zh: 'courseware_snapshot_title',
    en: 'courseware_snapshot_en_title'
  },
  user_name: {
    zh: 'user_name',
    en: 'user_en_name'
  }
};

function langKey(key, locale) {
  var val = LANGUAGE_MAPPING[key];

  if (val != null) {
    return locale === 'zh' ? val.zh : val.en;
  }

  return key;
}

function studentEnNameKey(key, val, locale) {
  if (locale === 'zh') {
    if (key === 'stu_name' && val.en_name != null && val.en_name.length > 0) {
      return 'en_name';
    }

    if (key === 'student_name' && val.student_en_name != null && val.student_en_name.length > 0) {
      return 'student_en_name';
    }

    if (key === 'stu_user_name' && val.user_en_name != null && val.user_en_name.length > 0) {
      return 'user_en_name';
    }
  }

  return null;
}

function langValue(val, path, supportLang, locale) {
  var paths = path.split('.');
  var last = paths[paths.length - 1];

  var ret = _objectSpread({}, val);

  paths.forEach(function (element) {
    if (ret != null) {
      if (supportLang === true && element === last) {
        var key = langKey(element, locale);

        if (typeof key === 'string') {
          if (ret[key] !== undefined) {
            var enNameKey = studentEnNameKey(element, val, locale);

            if (enNameKey != null) {
              ret = "".concat(ret[key], " (").concat(ret[enNameKey], ")");
            } else {
              ret = ret[key];
            }
          } else {
            ret = ret[element];
          }
        } else {
          ret = "".concat(ret[key[0]], ".").concat(ret[key[1]]);
        }
      } else {
        ret = ret[element];
      }
    }
  });
  return ret;
}

var lang = {
  methods: {
    languageKey: function languageKey(key) {
      return langKey(key, this.$i18n.locale);
    },
    valueFromPath: function valueFromPath(val, path, supportLang) {
      return langValue(val, path, supportLang, this.$i18n.locale);
    }
  }
};
var list = {
  data: function data() {
    return {
      meta: {
        total: 0,
        current_page: 1,
        page_size: 10
      },
      query: {
        page: 1,
        per_page: 10
      },
      dataList: [],
      listloading: false,
      editDialogVisible: false,
      editObject: null,
      columns: []
    };
  },
  methods: {
    handlePageTurn: function handlePageTurn(val) {
      this.query.page = val;
      this.loadList();
    },
    handlePageSize: function handlePageSize(size) {
      this.query.per_page = size;
      this.loadList();
    },
    handleReset: function handleReset() {
      this.query.page = 1;
      this.loadList();
    },
    handleAdd: function handleAdd() {
      this.editObject = null;
      this.editDialogVisible = true;
    },
    handleEdit: function handleEdit(val) {
      this.editObject = val;
      this.editDialogVisible = true;
    },
    handleSearch: function handleSearch() {
      this.query.page = 1;
      this.loadList();
    },
    handleDialogConfirm: function handleDialogConfirm() {
      this.loadList();
    },
    handleDialogClose: function handleDialogClose() {
      this.editObject = null;
    },
    makeColumns: function makeColumns(labelPrefix, columnWidthOptions) {
      if (this.dataList.length > 0) {
        if (labelPrefix == null) {
          labelPrefix = 'table';
        }

        this.columns = Object.keys(this.dataList[0]).map(function (k) {
          return {
            key: k,
            label: CONVERTED_LABELS.indexOf(k) >= 0 ? "".concat(labelPrefix, ".").concat(k) : k,
            width: columnWidthOptions != null ? columnWidthOptions[k] || columnWidthOptions.default_width : null
          };
        });
      }
    },
    languageKey: function languageKey(key) {
      return langKey(key, this.$i18n.locale);
    },
    valueFromPath: function valueFromPath(val, path, supportLang) {
      return langValue(val, path, supportLang, this.$i18n.locale);
    }
  }
};
var shop = {
  computed: {
    shopCode: function shopCode() {
      return Cookies.get('shopCode');
    }
  }
};
var nav = {
  methods: {
    viewUserDetail: function viewUserDetail(code) {
      this.$router.push({
        path: '/users/detail',
        query: {
          code: code
        }
      });
    },
    viewCardDetail: function viewCardDetail(cardNumber) {
      this.$router.push({
        path: '/cards/detail',
        query: {
          card_number: cardNumber
        }
      });
    }
  }
};
var exportCsv = {
  data: function data() {
    return {
      exportLoading: false,
      list_exporting: [],
      page_exporting: 1,
      per_page_exporting: 100
    };
  },
  methods: {
    handleExport: function handleExport(e) {
      e.stopPropagation();
      this.list_exporting = [];
      this.page_exporting = 1;
      this.exportLoading = true;
      this.getExportingList();
    },
    getExportingList: function getExportingList() {
      var _this = this;

      var start = this.per_page_exporting * (this.page_exporting - 1) + 1;
      var end = this.per_page_exporting * this.page_exporting;
      this.$notify.info({
        title: "\u6B63\u5728\u52A0\u8F7D\u7B2C ".concat(start, " - ").concat(end, " \u6761"),
        message: '全部加载完成后将自动导出',
        duration: 500
      });
      this.exportRequest().then(function (res) {
        var dataList = res.data.items;

        if (dataList.length) {
          _this.list_exporting = _this.list_exporting.concat(dataList);
        }

        var total = res.data.total;

        if (_this.page_exporting * _this.per_page_exporting < total) {
          _this.page_exporting += 1;

          _this.getExportingList();
        } else {
          _this.$notify.success({
            title: '数据加载完成',
            message: '现在开始导出',
            duration: 1000
          });

          _this.startExport();
        }
      }).catch(function (error) {
        _this.$message.warning(error.message || '导出失败，请重试');

        _this.exportLoading = false;
      });
    },
    outputName: function outputName() {
      return 'export.xlsx';
    },
    startExport: function startExport() {
      var _this2 = this;

      this.exportLoading = false;
      var dataList = this.list_exporting.map(function (item) {
        return _this2.exportMap(item);
      });

      if (dataList.length > 0) {
        var worksheet = XLSX.utils.json_to_sheet(dataList);
        worksheet['!cols'] = Object.keys(dataList[0]).map(function () {
          return {
            wpx: 80
          };
        });
        var newWorkbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'Sheet1');
        XLSX.writeFile(newWorkbook, this.outputName());
      }

      this.list_exporting = [];
    }
  }
};
var statusTag = {
  methods: {
    cardStatus: function cardStatus(status) {
      switch (status) {
        case 0: // 未激活

        case -1:
          // 已作废
          return 'info';

        case 9:
          // 已过期
          return 'danger';

        case 2:
          // 可使用
          return 'success';

        case 4:
          // 已使用
          return 'warning';

        default:
          // 已激活
          // 送礼中
          return 'primary';
      }
    },
    giftStatus: function giftStatus(status) {
      switch (status) {
        case 1:
          // 送礼中
          return 'primary';

        case 2:
          // 已退回
          return 'warning';

        case 3:
          // 已完成
          return 'success';

        default:
          return '';
      }
    },
    orderStatus: function orderStatus(status) {
      switch (status) {
        case 0:
          // 待发货
          return 'primary';

        case 1:
          // 已发货
          return 'success';

        case -1:
          // 已作废
          return 'warning';

        default:
          return '';
      }
    }
  }
};
var editor = {
  components: {
    editor: Editor
  },
  data: function data() {
    return {
      apiKey: 'fdb0jtztfpcjdguen87v3d1ybi7a583lfloz5l00b0rp9go3',
      initParams: {
        language_url: '/static/tinymce/zh_CN.js',
        language: this.$i18n.locale === 'zh' ? 'zh_CN' : '',
        height: 500,
        menubar: false,
        plugins: ['advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks code fullscreen', 'insertdatetime media table paste code help wordcount image imagetools hr'],
        toolbar: 'undo redo | formatselect | bold italic backcolor forecolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent hr | removeformat | link image preview',
        image_dimensions: true,
        automatic_uploads: true,
        images_upload_handler: function images_upload_handler(blobInfo, success, failure) {
          var data = new FormData();
          data.append('file', blobInfo.blob());
          axios.post("".concat(Vue.prototype.$appBaseApi || process.env.VUE_APP_BASE_API, "/file/"), data, {
            headers: {
              Authorization: "hb ".concat(getToken())
            }
          }).then(function (res) {
            success(res.data.data.view_url);
          }).catch(function (err) {
            failure(err);
          });
        },
        branding: false
      }
    };
  }
};
var format = {
  methods: {
    formatPrice: function formatPrice(val) {
      return (val / 100.0).toFixed(2);
    },
    dateFromString: function dateFromString(str) {
      return new Date(str.split('+')[0]);
    },
    stringFromDate: function stringFromDate(date) {
      return parseTime(date, '{y}-{m}-{d} {h}:{i}:{s}');
    },
    formatTime: function formatTime(val) {
      if (val) {
        return parseTime(this.dateFromString(val), '{y}-{m}-{d} {h}:{i}:{s}');
      }

      return val;
    },
    formatTimeInData: function formatTimeInData(data) {
      return _objectSpread(_objectSpread({}, data), {}, {
        update_at: this.formatTime(data.update_at),
        create_at: this.formatTime(data.create_at),
        publish_at: this.formatTime(data.publish_at),
        last_reply_at: this.formatTime(data.last_reply_at),
        reply_at: this.formatTime(data.reply_at),
        pay_at: this.formatTime(data.pay_at)
      });
    },
    splitLines: function splitLines(str) {
      if (str === null) {
        return [];
      }

      if (str.length <= 30) {
        return [str];
      }

      return str.match(/(.|[\r\n]){1,30}/g);
    },
    formatToHourMinute: function formatToHourMinute(num) {
      if (typeof num !== 'number') {
        return '';
      }

      var hours = Math.floor(num / 60);
      var mins = num - hours * 60;
      return "".concat(hours).concat(this.$t('common.hour')).concat(mins).concat(this.$t('common.minute'));
    }
  }
};
var sort = {
  data: function data() {
    return {
      sortKey: null
    };
  },
  methods: {
    sort: function sort(str1, str2) {
      if (this.sortKey == null) {
        return 0;
      }

      str1 = str1[this.sortKey];
      str2 = str2[this.sortKey];
      var res = 0;

      for (var i = 0;; i += 1) {
        if (!str1[i] || !str2[i]) {
          res = str1.length - str2.length;
          break;
        }

        var char1 = str1[i];
        var char1Type = this.getChartType(char1);
        var char2 = str2[i];
        var char2Type = this.getChartType(char2); // console.log(char1Type, char2Type);
        // 类型相同的逐个比较字符

        if (char1Type[0] === char2Type[0]) {
          if (char1 === char2) {
            // eslint-disable-next-line no-continue
            continue;
          } else {
            if (char1Type[0] === 'zh') {
              res = char2.localeCompare(char1);
            } else if (char1Type[0] === 'en') {
              res = char1.charCodeAt(0) - char2.charCodeAt(0);
            } else {
              res = char1 - char2;
            }

            break;
          }
        } else {
          // 类型不同的，直接用返回的数字相减
          res = char1Type[1] - char2Type[1];
          break;
        }
      }

      return res;
    },
    getChartType: function getChartType(char) {
      // 数字可按照排序的要求进行自定义，我这边产品的要求是
      // 数字（0->9）->大写字母（A->Z）->小写字母（a->z）->中文拼音（a->z）
      if (/^[\u4e00-\u9fa5]$/.test(char)) {
        return ['zh', 300];
      }

      if (/^[a-zA-Z]$/.test(char)) {
        return ['en', 200];
      }

      if (/^[0-9]$/.test(char)) {
        return ['number', 100];
      }

      return ['others', 999];
    }
  }
};
var formRules = {
  data: function data() {
    return {
      rules: {}
    };
  },
  created: function created() {
    var _this3 = this;

    var checkPrice = function checkPrice(rule, value, callback) {
      if (!value) {
        return callback(new Error(_this3.priceRequireRule));
      }

      if (parseInt(value * 100, 10) > 0) {
        return callback();
      }

      return callback(new Error(_this3.priceIncorrectRule));
    };

    this.rules = {
      title: [{
        required: true,
        trigger: 'blur',
        message: this.titleRequireRule
      }],
      en_title: [{
        required: true,
        trigger: 'blur',
        message: this.titleRequireRule
      }],
      price: [{
        required: true,
        trigger: 'blur',
        message: this.priceRequireRule
      }, {
        validator: checkPrice,
        trigger: 'blur'
      }],
      intro: [{
        required: true,
        trigger: 'blur',
        message: this.introRequireRule
      }],
      subject_category_id: [{
        required: true,
        message: this.subjectRequireRule
      }],
      grade_category_id_list: [{
        required: true,
        message: this.gradeRequireRule
      }],
      teacher_id: [{
        required: this.$appModule !== 'teacher',
        message: this.teacherRequireRule
      }]
    };
  },
  computed: {
    priceRequireRule: function priceRequireRule() {
      return this.$t('rules.price_require');
    },
    priceIncorrectRule: function priceIncorrectRule() {
      return this.$t('rules.price_incorrect');
    },
    titleRequireRule: function titleRequireRule() {
      return this.$t('rules.title_require');
    },
    teacherRequireRule: function teacherRequireRule() {
      return this.$t('rules.teacher_require');
    },
    subjectRequireRule: function subjectRequireRule() {
      return this.$t('rules.subject_require');
    },
    gradeRequireRule: function gradeRequireRule() {
      return this.$t('rules.grade_require');
    },
    introRequireRule: function introRequireRule() {
      return this.$t('rules.intro_require');
    }
  }
};
export default {
  list: list,
  file: file,
  shop: shop,
  nav: nav,
  image: image,
  exportCsv: exportCsv,
  statusTag: statusTag,
  editor: editor,
  format: format,
  sort: sort,
  formRules: formRules,
  lang: lang
};