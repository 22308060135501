//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import mixins from '@/utils/mixins';
export default {
  name: 'SidebarLogo',
  mixins: [mixins.file, mixins.shop],
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      logo: '/static/images/logo.jpg'
    };
  },
  computed: {
    computedTitle: function computedTitle() {
      return this.$t('title');
    }
  },
  created: function created() {}
};