var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.$i18n.locale == "zh" ? _vm.name : _vm.en_name)
                  ),
                ]),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v(_vm._s(_vm.$t("login.logout"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c(
                  "div",
                  { staticClass: "nav-item" },
                  [
                    _c("div", { staticClass: "text" }, [
                      _vm._v(" " + _vm._s(_vm.$t("nav.language")) + " "),
                    ]),
                    _c("svg-icon", { attrs: { "icon-class": "language" } }),
                  ],
                  1
                ),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.changeLanguage("en")
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("English"),
                      ]),
                    ]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.changeLanguage("zh")
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("中文"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c(
                  "div",
                  { staticClass: "nav-item" },
                  [
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("lang." + _vm.timezones[_vm.timezone].key)
                          ) +
                          " "
                      ),
                    ]),
                    _c("svg-icon", { attrs: { "icon-class": "timezone" } }),
                  ],
                  1
                ),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                _vm._l(_vm.timezones, function (item, index) {
                  return _c(
                    "el-dropdown-item",
                    {
                      key: item.id,
                      nativeOn: {
                        click: function ($event) {
                          return _vm.changeTimezone(index)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v(_vm._s(_vm.$t("lang." + item.key))),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.$appModule != "admin"
        ? _c("div", { staticClass: "right-menu" }, [
            _c("div", { staticClass: "avatar-container right-menu-div" }, [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c(
                  "div",
                  { staticClass: "nav-item", on: { click: _vm.checkMessage } },
                  [
                    _c("div", { staticClass: "text" }, [
                      _vm._v(" " + _vm._s(_vm.$t("nav.message")) + " "),
                    ]),
                    _c(
                      "div",
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "nav-message" },
                        }),
                        _vm.unreadCount > 0
                          ? _c("div", { staticClass: "unread" }, [
                              _c("div", [
                                _vm._v(" " + _vm._s(_vm.unreadCount) + " "),
                              ]),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }