import request from '@/utils/http';
export function authLogin() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return request({
    url: '/auth/login',
    method: 'post',
    data: data
  });
}
export function authInfo() {
  return request({
    url: '/auth/info',
    method: 'get'
  });
}
export function updateInfo(data) {
  return request({
    url: '/auth/info',
    method: 'put',
    data: data
  });
}
export function requestVCode(data) {
  return request({
    url: '/auth/login/req_v_code',
    method: 'post',
    data: data
  });
}