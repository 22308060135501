import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";

/* eslint-disable global-require */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ElementUILocale from 'element-ui/lib/locale';
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
Vue.use(VueI18n);
var DEFAULT_LANG = 'en';
var LOCALE_KEY = 'DemoLanguage';
var locales = {
  zh: require('./zh.json'),
  en: require('./en.json')
};
var i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages: locales,
  silentTranslationWarn: true
});
var UIlocales = {
  zh: zhLocale,
  en: enLocale
};

var setUIlocales = function setUIlocales(lang) {
  switch (lang) {
    case 'zh':
      return UIlocales.zh;

    default:
      return UIlocales.en;
  }
};

export var setup = function setup(lang) {
  if (lang === undefined) {
    lang = window.localStorage.getItem(LOCALE_KEY);

    if (locales[lang] === undefined) {
      lang = DEFAULT_LANG;
    }
  }

  window.localStorage.setItem(LOCALE_KEY, lang);
  Object.keys(locales).forEach(function (item) {
    document.body.classList.remove("lang-".concat(item));
  });
  document.body.classList.add("lang-".concat(lang));
  document.body.setAttribute('lang', lang);
  Vue.config.lang = lang;
  i18n.locale = lang;
  ElementUILocale.use(setUIlocales(lang));
};
setup();
export default i18n;