import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";

/* eslint-disable no-unused-vars */
import { getToken, setToken, removeToken } from '@/utils/auth';
import { resetRouter } from '@/router';
import { authLogin, authInfo } from '@/api/auth';
import Cookies from 'js-cookie'; // get user info remote

var state = {
  token: getToken(),
  name: '',
  en_name: '',
  username: '',
  permissions: []
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(State, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(State, name) {
    state.name = name;
  },
  SET_ENNAME: function SET_ENNAME(State, en_name) {
    state.en_name = en_name;
  },
  SET_USERNAME: function SET_USERNAME(State, username) {
    state.username = username;
  },
  SET_PERMISSIONS: function SET_PERMISSIONS(State, permissions) {
    state.permissions = permissions;
  },
  RESET_STATE: function RESET_STATE(State) {
    state.permissions = [];
    state.name = '';
    state.username = '';
    state.role = {};
  }
};
var actions = {
  getInfo: function getInfo(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      authInfo().then(function (response) {
        var data = response.data;
        commit('SET_NAME', data.name || data.username);
        commit('SET_ENNAME', data.en_name || data.username);
        commit('SET_USERNAME', data.username);
        var username = data.username;
        commit('SET_PERMISSIONS', [{
          code: 'default'
        }]);
        resolve({
          username: username,
          permissions: [{
            code: 'default'
          }]
        });
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  login: function login(_ref2, userInfo) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      authLogin(userInfo).then(function (response) {
        var data = response.data;
        commit('SET_TOKEN', data.access_token);
        setToken(data.access_token);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      commit('SET_PERMISSIONS', []);
      removeToken();
      resetRouter();
      resolve();
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      removeToken();
      commit('RESET_STATE');
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};